import React from 'react'
import { Trash2, Plus, Minus, Package, RotateCcw } from 'lucide-react'

interface ItemCardProps {
  item: {
    sku: string
    qty: number
    price: number
    originalPrice: number
    cost: number
    title?: string
    brand?: string
  }
  onRemove: () => void
  onQuantityChange: (newQuantity: number) => void
  onPriceChange: (newPrice: number) => void
  onCostChange: (newCost: number) => void
  onAddonsClick: () => void
  resetPrice: (sku: string) => void
}

const ItemCard: React.FC<ItemCardProps> = ({
  item,
  onRemove,
  onQuantityChange,
  onPriceChange,
  onCostChange,
  onAddonsClick,
  resetPrice,
}) => {
  const profit = item.price - item.cost
  const marginPercentage = ((profit / item.price) * 100).toFixed(2)

  // Format number to always show 2 decimal places
  const formatNumber = (num: number | null | undefined): string => {
    if (num === null || num === undefined) {
      return '0.00' // or return an empty string '', depending on your preference
    }

    return num.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }


  return (
    <div className="bg-space-900 text-space-100 rounded-lg p-4 w-full mb-2">
      <div className="flex gap-4">
        <div className="flex-shrink-0 w-32 h-32">
          <div className="w-full h-full bg-space-700 rounded-md flex items-center justify-center">
            <Package className="h-16 w-16 text-space-500" />
          </div>
        </div>
        <div className="flex-grow grid gap-4 w-full">
          <div className="grid grid-cols-2 gap-4">
            <div>
              {item.title && <h3 className="font-semibold text-lg text-space-50">{item.title}</h3>}
              {item.brand && <p className="text-sm text-space-300">{item.brand}</p>}
              <p className="text-sm font-medium text-space-200">SKU: {item.sku}</p>
            </div>
            <div className="text-right">
              <p className="text-lg font-semibold text-space-50">${formatNumber(item.price)}</p>
              <p className="text-sm text-space-300">Cost: ${formatNumber(item.cost)}</p>
              <p className="text-sm text-space-300">
                Profit: ${formatNumber(profit)} ({marginPercentage}%)
              </p>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4 items-end">
            <div className="space-y-1">
              <label htmlFor={`quantity-${item.sku}`} className="block text-xs font-medium text-space-300">Quantity</label>
              <div className="flex items-center space-x-1">
                <button
                  onClick={() => onQuantityChange(Math.max(0, item.qty - 1))}
                  className="p-1 bg-space-700 text-space-200 rounded-md hover:bg-space-600 focus:outline-none focus:ring-1 focus:ring-space-500 focus:ring-opacity-50"
                  aria-label="Decrease quantity"
                >
                  <Minus className="h-3 w-3" />
                </button>
                <input
                  id={`quantity-${item.sku}`}
                  type="number"
                  value={item.qty}
                  onChange={(e) => onQuantityChange(Math.max(0, Number(e.target.value)))}
                  className="p-1 bg-space-700 text-space-200 rounded-md hover:bg-space-600 focus:outline-none focus:ring-1 focus:ring-space-500 focus:ring-opacity-50"
                  min={0}
                  aria-label="Quantity"
                  style={{ appearance: 'none', MozAppearance: 'textfield' }}
                />
                <button
                  onClick={() => onQuantityChange(item.qty + 1)}
                  className="p-1 bg-space-700 text-space-200 rounded-md hover:bg-space-600 focus:outline-none focus:ring-1 focus:ring-space-500 focus:ring-opacity-50"
                  aria-label="Increase quantity"
                >
                  <Plus className="h-3 w-3" />
                </button>
              </div>
            </div>
            <div className="space-y-1">
              <label htmlFor={`price-${item.sku}`} className="block text-xs font-medium text-space-300">Price ($)</label>
              <div className="flex items-center">
                <input
                  id={`price-${item.sku}`}
                  type="number"
                  value={item.price}
                  onChange={(e) => onPriceChange(Number(e.target.value))}
                  min={0}
                  step={0.01}
                  className="w-full rounded-md bg-space-700 border-space-600 text-space-100 focus:border-space-500 focus:ring focus:ring-space-500 focus:ring-opacity-50 text-sm py-1"
                  style={{ appearance: 'none', MozAppearance: 'textfield' }}
                />
                <button
                  onClick={() => resetPrice(item.sku)}
                  className="ml-2 p-1 text-space-300 hover:text-space-100 focus:outline-none"
                  aria-label="Reset Price"
                >
                  <RotateCcw className="h-5 w-5" />
                </button>
              </div>
            </div>
            <div className="space-y-1">
              <label htmlFor={`cost-${item.sku}`} className="block text-xs font-medium text-space-300">Cost ($)</label>
              <input
                id={`cost-${item.sku}`}
                type="number"
                value={item.cost}
                onChange={(e) => onCostChange(Number(e.target.value))}
                min={0}
                step={0.01}
                className="w-full rounded-md bg-space-800 border-space-700 text-space-100 focus:border-space-500 focus:ring focus:ring-space-500 focus:ring-opacity-50 text-sm py-1"
                style={{ appearance: 'none', MozAppearance: 'textfield' }}
                disabled
              />
            </div>
          </div>
          <div className="flex flex-wrap gap-2 items-center">
            <button
              onClick={() => onPriceChange(item.cost / (1 - 0.2))}
              className="px-3 py-1 bg-space-600 text-space-100 rounded-md text-sm hover:bg-space-500 focus:outline-none focus:ring-2 focus:ring-space-500 focus:ring-opacity-50"
            >
              20% Margin
            </button>
            <button
              onClick={() => onPriceChange(item.cost / (1 - 0.25))}
              className="px-3 py-1 bg-space-600 text-space-100 rounded-md text-sm hover:bg-space-500 focus:outline-none focus:ring-2 focus:ring-space-500 focus:ring-opacity-50"
            >
              25% Margin
            </button>
            <button
              onClick={onAddonsClick}
              className="px-3 py-1 bg-space-600 text-space-100 rounded-md text-sm hover:bg-space-500 focus:outline-none focus:ring-2 focus:ring-space-500 focus:ring-opacity-50"
            >
              Add-ons
            </button>
            <button
              onClick={onRemove}
              className="ml-auto p-2 text-red-400 hover:text-red-300 focus:outline-none"
              aria-label="Remove product"
            >
              <Trash2 className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ItemCard