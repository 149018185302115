import { application } from "./application"

import AnimationController from "./animation_controller"
application.register("animation", AnimationController)

import FlashController from "./flash_controller"
application.register("flash", FlashController)

import ProgressController from "./progress_controller"
application.register("progress", ProgressController)

import SidebarController from "./sidebar"
application.register("sidebar", SidebarController)

import ThemeSwitcherController from "./theme_switcher_controller"
application.register("theme-switcher", ThemeSwitcherController)

import RailsNestedForm from '@stimulus-components/rails-nested-form'
application.register('nested-form', RailsNestedForm)

// import OrderFormController from "./order_form_controller"
// application.register("order-form", OrderFormController)

// import OrderItemController from "./order_item_controller"
// application.register("order-item", OrderItemController)

// import CustomerSearchController from "./customer_search_controller"
// application.register("customer-search", CustomerSearchController)

