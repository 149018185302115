import React from 'react'

interface PaymentDetails {
  method: 'credit_card' | 'bank_transfer' | 'credit' | 'cash'
  cardNumber?: string
  expiry?: string
  bankName?: string
  accountNumber?: string
  amount?: number
}

interface PaymentDetailsInputProps {
  paymentDetails: PaymentDetails
  setPaymentDetails: (details: PaymentDetails) => void
}

export const PaymentDetailsInput: React.FC<PaymentDetailsInputProps> = ({ paymentDetails, setPaymentDetails }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target
    setPaymentDetails({ ...paymentDetails, [name]: value })
  }

  const handleMethodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const method = e.target.value as PaymentDetails['method']
    setPaymentDetails({ method })
  }

  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-space-200 mb-1">Payment Method</label>
        <select
          name="method"
          value={paymentDetails.method}
          onChange={handleMethodChange}
          className="w-full p-2 bg-space-900 text-space-100 border-space-900 rounded focus:outline-none focus:border-blue-500"
        >
          <option value="credit_card">Credit Card</option>
          <option value="bank_transfer">Bank Transfer</option>
          <option value="credit">Credit</option>
          <option value="cash">Cash</option>
          <option value="none">None</option>
        </select>
      </div>
      {paymentDetails.method === 'credit_card' && (
        <>
          <div>
            <label className="block text-sm font-medium text-space-200 mb-1">Card Number</label>
            <input
              type="text"
              name="cardNumber"
              placeholder="Card Number"
              value={paymentDetails.cardNumber || ''}
              onChange={handleChange}
              className="w-full p-2 bg-space-900 text-space-100 border-space-900 rounded placeholder-space-400 focus:outline-none focus:border-blue-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-space-200 mb-1">Expiry (MM/YY)</label>
            <input
              type="text"
              name="expiry"
              placeholder="MM/YY"
              value={paymentDetails.expiry || ''}
              onChange={handleChange}
              className="w-full p-2 bg-space-900 text-space-100 border-space-900 rounded placeholder-space-400 focus:outline-none focus:border-blue-500"
            />
          </div>
        </>
      )}
      {paymentDetails.method === 'bank_transfer' && (
        <>
          <div>
            <label className="block text-sm font-medium text-space-200 mb-1">Bank Name</label>
            <input
              type="text"
              name="bankName"
              placeholder="Bank Name"
              value={paymentDetails.bankName || ''}
              onChange={handleChange}
              className="w-full p-2 bg-space-900 text-space-100 border-space-900 rounded placeholder-space-400 focus:outline-none focus:border-blue-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-space-200 mb-1">Account Number</label>
            <input
              type="text"
              name="accountNumber"
              placeholder="Account Number"
              value={paymentDetails.accountNumber || ''}
              onChange={handleChange}
              className="w-full p-2 bg-space-900 text-space-100 border-space-900 rounded placeholder-space-400 focus:outline-none focus:border-blue-500"
            />
          </div>
        </>
      )}
      {(paymentDetails.method === 'credit' || paymentDetails.method === 'cash') && (
        <div>
          <label className="block text-sm font-medium text-space-200 mb-1">Amount</label>
          <input
            type="number"
            name="amount"
            placeholder="Amount"
            value={paymentDetails.amount || ''}
            onChange={handleChange}
            className="w-full p-2 bg-space-900 text-space-100 border-space-900 rounded placeholder-space-400 focus:outline-none focus:border-blue-500"
          />
        </div>
      )}
    </div>
  )
}