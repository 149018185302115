import React, { useState } from 'react'

const NoteTypes = ['General Note', 'Customer Service Note', 'Billing Note', 'Delivery Note']

export const TabContent: React.FC<{ tab: string }> = ({ tab }) => {
  const [noteType, setNoteType] = useState<string>('General Note')
  const [noteContent, setNoteContent] = useState<string>('')

  const EmptyTabNotice = ({ message }: { message: string }) => (
    <div className='flex py-8 items-center justify-center text-center text-xl font-bold text-space-500'>
      {message}
    </div>
  )

  switch (tab) {
    case 'Notes':
      return (
        <div className="space-y-4">
          <div className="flex space-x-4">
            {NoteTypes.map((type) => (
              <label key={type} className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio text-blue-600 mr-2"
                  name="note-type"
                  value={type}
                  checked={noteType === type}
                  onChange={() => setNoteType(type)}
                />
                <span className="text-space-200">{type}</span>
              </label>
            ))}
          </div>
          <textarea
            className="w-full h-32 p-2 bg-space-900 text-space-100 border-space-900 rounded placeholder-space-400"
            placeholder={`Add ${noteType.toLowerCase()} here...`}
            value={noteContent}
            onChange={(e) => setNoteContent(e.target.value)}
          ></textarea>
        </div>
      )
    case 'Order Documents':
      return <EmptyTabNotice message="Order documents will be displayed here once available." />
    case 'Shipping Rates':
      return <EmptyTabNotice message="Shipping rates and options will be shown here when ready." />
    case 'Purchase Orders':
      return <EmptyTabNotice message="Related purchase orders will be listed here once created." />
    case 'Returns':
      return <EmptyTabNotice message="Return information and history will be displayed here when applicable." />
    case 'P&L':
      return <EmptyTabNotice message="Profit and Loss statement for this order will be shown here after processing." />
    case 'Inventory':
      return <EmptyTabNotice message="Inventory information related to this order will be displayed here once updated." />
    case 'Timeline':
      return <EmptyTabNotice message="Timeline will appear after the order is created and processed." />
    default:
      return <EmptyTabNotice message="No content available for this tab." />
  }
}

export default TabContent