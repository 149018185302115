import React, { useState } from 'react'

interface Address {
  id: number
  customer_id: number
  name: string
  contact_name: string
  country: string
  address_1: string
  address_2: string
  zip_code: string
  city: string
  state: string
  phone: string
  email: string
  fax: string
  billing_default: boolean
  shipping_default: boolean
}

interface Customer {
  id: number
  name: string
  email: string
  addresses: {
    billing: Address | null
    shipping: Address | null
  }
}

interface CustomerEditModalProps {
  customer: Customer | null
  onClose: () => void
  onSave: (customer: Customer) => void
}

export const CustomerEditModal: React.FC<CustomerEditModalProps> = ({ customer, onClose, onSave }) => {
  const [editedCustomer, setEditedCustomer] = useState<Customer>(customer || {
    id: 0,
    name: '',
    email: '',
    addresses: {
      billing: null,
      shipping: null
    }
  })

  const [sameAsShipping, setSameAsShipping] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, addressType?: 'billing' | 'shipping') => {
    const { name, value } = e.target
    if (addressType) {
      setEditedCustomer(prev => ({
        ...prev,
        addresses: {
          ...prev.addresses,
          [addressType]: {
            ...prev.addresses[addressType],
            [name]: value
          }
        }
      }))
    } else {
      setEditedCustomer(prev => ({ ...prev, [name]: value }))
    }
  }

  const handleSameAsShippingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSameAsShipping(e.target.checked)
    if (e.target.checked) {
      setEditedCustomer(prev => ({
        ...prev,
        addresses: {
          ...prev.addresses,
          shipping: prev.addresses.billing
        }
      }))
    }
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center overflow-y-auto">
      <div className="bg-space-800 p-6 rounded-lg w-3/4 max-w-4xl my-8">
        <h2 className="text-2xl font-bold mb-4">Edit Customer Details</h2>
        <form onSubmit={(e) => {
          e.preventDefault()
          onSave(editedCustomer)
        }}>
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-space-200">Full Name</label>
              <input
                type="text"
                name="name"
                value={editedCustomer.name}
                onChange={(e) => handleChange(e)}
                className="mt-1 w-full p-2 bg-space-900 text-space-100 border-space-900 rounded"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-space-200">Email</label>
              <input
                type="email"
                name="email"
                value={editedCustomer.email}
                onChange={(e) => handleChange(e)}
                className="mt-1 w-full p-2 bg-space-900 text-space-100 border-space-900 rounded"
              />
            </div>

            <div className="col-span-2">
              <h3 className="text-xl font-semibold mb-2">Billing Address</h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-space-200">Contact Name</label>
                  <input
                    type="text"
                    name="contact_name"
                    value={editedCustomer.addresses.billing?.contact_name || ''}
                    onChange={(e) => handleChange(e, 'billing')}
                    className="mt-1 w-full p-2 bg-space-900 text-space-100 border-space-900 rounded"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-space-200">Country</label>
                  <input
                    type="text"
                    name="country"
                    value={editedCustomer.addresses.billing?.country || ''}
                    onChange={(e) => handleChange(e, 'billing')}
                    className="mt-1 w-full p-2 bg-space-900 text-space-100 border-space-900 rounded"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-space-200">Address 1</label>
                  <input
                    type="text"
                    name="address_1"
                    value={editedCustomer.addresses.billing?.address_1 || ''}
                    onChange={(e) => handleChange(e, 'billing')}
                    className="mt-1 w-full p-2 bg-space-900 text-space-100 border-space-900 rounded"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-space-200">Address 2</label>
                  <input
                    type="text"
                    name="address_2"
                    value={editedCustomer.addresses.billing?.address_2 || ''}
                    onChange={(e) => handleChange(e, 'billing')}
                    className="mt-1 w-full p-2 bg-space-900 text-space-100 border-space-900 rounded"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-space-200">City</label>
                  <input
                    type="text"
                    name="city"
                    value={editedCustomer.addresses.billing?.city || ''}
                    onChange={(e) => handleChange(e, 'billing')}
                    className="mt-1 w-full p-2 bg-space-900 text-space-100 border-space-900 rounded"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-space-200">State</label>
                  <input
                    type="text"
                    name="state"
                    value={editedCustomer.addresses.billing?.state || ''}
                    onChange={(e) => handleChange(e, 'billing')}
                    className="mt-1 w-full p-2 bg-space-900 text-space-100 border-space-900 rounded"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-space-200">ZIP Code</label>
                  <input
                    type="text"
                    name="zip_code"
                    value={editedCustomer.addresses.billing?.zip_code || ''}
                    onChange={(e) => handleChange(e, 'billing')}
                    className="mt-1 w-full p-2 bg-space-900 text-space-100 border-space-900 rounded"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-space-200">Phone</label>
                  <input
                    type="text"
                    name="phone"
                    value={editedCustomer.addresses.billing?.phone || ''}
                    onChange={(e) => handleChange(e, 'billing')}
                    className="mt-1 w-full p-2 bg-space-900 text-space-100 border-space-900 rounded"
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2">
              <h3 className="text-xl font-semibold mb-2">Shipping Address</h3>
              <div className="mb-4">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={sameAsShipping}
                    onChange={handleSameAsShippingChange}
                    className="mr-2 rounded"
                  />
                  <span className="text-space-200">Same as Billing Address</span>
                </label>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-space-200">Contact Name</label>
                  <input
                    type="text"
                    name="contact_name"
                    value={editedCustomer.addresses.shipping?.contact_name || ''}
                    onChange={(e) => handleChange(e, 'shipping')}
                    disabled={sameAsShipping}
                    className="mt-1 w-full p-2 bg-space-900 text-space-100 border-space-900 rounded disabled:opacity-50"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-space-200">Country</label>
                  <input
                    type="text"
                    name="country"
                    value={editedCustomer.addresses.shipping?.country || ''}
                    onChange={(e) => handleChange(e, 'shipping')}
                    disabled={sameAsShipping}
                    className="mt-1 w-full p-2 bg-space-900 text-space-100 border-space-900 rounded disabled:opacity-50"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-space-200">Address 1</label>
                  <input
                    type="text"
                    name="address_1"
                    value={editedCustomer.addresses.shipping?.address_1 || ''}
                    onChange={(e) => handleChange(e, 'shipping')}
                    disabled={sameAsShipping}
                    className="mt-1 w-full p-2 bg-space-900 text-space-100 border-space-900 rounded disabled:opacity-50"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-space-200">Address 2</label>
                  <input
                    type="text"
                    name="address_2"
                    value={editedCustomer.addresses.shipping?.address_2 || ''}
                    onChange={(e) => handleChange(e, 'shipping')}
                    disabled={sameAsShipping}
                    className="mt-1 w-full p-2 bg-space-900 text-space-100 border-space-900 rounded disabled:opacity-50"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-space-200">City</label>
                  <input
                    type="text"
                    name="city"
                    value={editedCustomer.addresses.shipping?.city || ''}
                    onChange={(e) => handleChange(e, 'shipping')}
                    disabled={sameAsShipping}
                    className="mt-1 w-full p-2 bg-space-900 text-space-100 border-space-900 rounded disabled:opacity-50"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-space-200">State</label>
                  <input
                    type="text"
                    name="state"
                    value={editedCustomer.addresses.shipping?.state || ''}
                    onChange={(e) => handleChange(e, 'shipping')}
                    disabled={sameAsShipping}
                    className="mt-1 w-full p-2 bg-space-900 text-space-100 border-space-900 rounded disabled:opacity-50"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-space-200">ZIP Code</label>
                  <input
                    type="text"
                    name="zip_code"
                    value={editedCustomer.addresses.shipping?.zip_code || ''}
                    onChange={(e) => handleChange(e, 'shipping')}
                    disabled={sameAsShipping}
                    className="mt-1 w-full p-2 bg-space-900 text-space-100 border-space-900 rounded disabled:opacity-50"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-space-200">Phone</label>
                  <input
                    type="text"
                    name="phone"
                    value={editedCustomer.addresses.shipping?.phone || ''}
                    onChange={(e) => handleChange(e, 'shipping')}
                    disabled={sameAsShipping}
                    className="mt-1 w-full p-2 bg-space-900 text-space-100 border-space-900 rounded disabled:opacity-50"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-space-600 text-space-100 rounded hover:bg-space-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}