import React, { useState, useEffect } from 'react'

interface SubProduct {
  sku: string
  title: string
  price: number
  originalPrice: number
  cost: number
  qty: number
  isAddon: boolean
}

interface SubProductsModalProps {
  sku: string
  onClose: () => void
  onAdd: (subProducts: SubProduct[]) => void
}

export const SubProductsModal: React.FC<SubProductsModalProps> = ({ sku, onClose, onAdd }) => {
  const [subProducts, setSubProducts] = useState<SubProduct[]>([])
  const [selectedProducts, setSelectedProducts] = useState<SubProduct[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchSubProducts = async () => {
      setIsLoading(true)
      try {
        const response = await fetch(`/api/v1/products/sku/${sku}/options`)
        if (!response.ok) {
          throw new Error(`Failed to fetch product options for SKU: ${sku}`)
        }
        const data = await response.json()
        if (data.success && Array.isArray(data.data)) {
          setSubProducts(data.data.map((item: SubProduct) => ({
            sku: item.sku,
            title: item.title,
            price: item.price,
            originalPrice: item.price,
            cost: item.cost,
            qty: 1,
            isAddon: true
          })))
        } else {
          setSubProducts([])
        }
      } catch (error) {
        console.error(`Error fetching product options for SKU: ${sku}`, error)
        setSubProducts([])
      } finally {
        setIsLoading(false)
      }
    }
    fetchSubProducts()
  }, [sku])

  const toggleProductSelection = (product: SubProduct) => {
    setSelectedProducts(prev => {
      const isSelected = prev.some(p => p.sku === product.sku)
      if (isSelected) {
        return prev.filter(p => p.sku !== product.sku)
      } else {
        return [...prev, product]
      }
    })
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-space-800 p-6 rounded-lg w-3/4 max-w-4xl shadow-xl">
        <h2 className="text-2xl font-bold mb-4">Addons for SKU: {sku}</h2>
        {isLoading ? (
          <p className="text-space-200">Loading addibs...</p>
        ) : subProducts.length === 0 ? (
          <p className="text-space-200">No addons available for this SKU.</p>
        ) : (
          <div className="grid grid-cols-1 gap-4">
            {subProducts.map(product => (
              <div key={product.sku} className="flex items-center gap-2 bg-space-700 p-3 rounded">
                <input
                  type="checkbox"
                  id={product.sku}
                  checked={selectedProducts.some(p => p.sku === product.sku)}
                  onChange={() => toggleProductSelection(product)}
                  className="form-checkbox rounded"
                />
                <label htmlFor={product.sku} className="text-space-200 font-bold flex-grow">
                  {product.title}
                </label>
                <span className="font-bold text-space-200 text-lg">${product.price.toFixed(2)}</span>
              </div>
            ))}
          </div>
        )}
        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-space-600 text-space-100 rounded hover:bg-space-500"
          >
            Cancel
          </button>
          <button
            onClick={() => onAdd(selectedProducts)}
            className={`px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500 ${subProducts.length <= 0 && 'opacity-15'}`}
            disabled={selectedProducts.length === 0 || subProducts.length <= 0}
          >
            Add Selected
          </button>
        </div>
      </div>
    </div>
  )
}