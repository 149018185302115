import React, { useState, useEffect, useRef } from 'react'
import { ChevronDown, ChevronUp, Search, Loader, Plus, Edit, Trash2, RefreshCw } from 'lucide-react'
import { TabContent } from './order_components/TabContent'
import { CustomerEditModal } from './order_components/CustomerEditModal'
import { SubProductsModal } from './order_components/SubProductsModal'
import { PaymentDetailsInput } from './order_components/PaymentDetailsInput'
import ItemCard from './order_components/ItemCard' // Import the new ItemCard component

type OrderdProps = {
  order?: number
}

interface OrderItem {
  title?: string
  brand?: string
  sku: string
  qty: number
  price: number
  originalPrice: number
  m20?: number
  m25?: number
  cost: number
  parentSku?: string
  isAddon?: boolean
}

interface Address {
  name: string
  email: string
  phone: string
  fax: string
  address_1: string
  address_2: string
  city: string
  state: string
  zipcode: number
  country: string
}

interface Customer {
  id: number
  name: string
  email: string
  addresses: {
    billing: Address | null
    shipping: Address | null
  }
}

interface PaymentDetails {
  method: 'credit_card' | 'bank_transfer' | 'credit' | 'cash' | 'none'
  cardNumber?: string
  expiry?: string
  bankName?: string
  accountNumber?: string
  amount?: number
}

interface OrderData {
  customer: Customer | null
  paymentDetails: PaymentDetails
  orderItems: OrderItem[]
  orderTotal: number
  taxes: number
  totalProfit: number
  profitPercentage: number
  notes: string
  orderStatus: string
  orderShippingStatus: string
  orderPaymentStatus: string
  orderType: string
  orderSubType: string
  orderSource: string
  orderCompany: string
  orderSalesRep: string
  createdDate?: Date | null
  orderDate?: Date | null
  promiseDate?: Date | null
}

interface Product {
  id: number
  title: string
  brand: string
  sku: string
  cost: number
  price: number
  m20: number
  m25: number
  inventory: number
}


interface Company {
  id: number
  sub_domain: string
  name: string
}

interface User {
  id: number
  username: string
  is_selected: boolean
}

interface CollapsibleSectionProps {
  title: string
  children: React.ReactNode
}

// function addWorkingDays(date, days) {
//   let currentDate = new Date(date)
//   let addedDays = 0

//   while (addedDays < days) {
//     currentDate.setDate(currentDate.getDate() + 1)
//     // Check if it's a weekday (Monday to Friday)
//     if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
//       addedDays++
//     }
//   }

//   return currentDate
// }



const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <section className="bg-space-800 p-4 rounded">
      <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
        <h2 className="text-lg font-semibold text-space-100">{title}</h2>
        {isOpen ? <ChevronUp size={20} className="text-space-100" /> : <ChevronDown size={20} className="text-space-100" />}
      </div>
      {isOpen && <div className="mt-2 text-space-200">{children}</div>}
    </section>
  )
}

const NewOrder: React.FC<OrderdProps> = ({ order }) => {
  const [autoAddOnScan, setAutoAddOnScan] = useState(false)
  const [skuInput, setSkuInput] = useState<string>('')
  const [customerSearch, setCustomerSearch] = useState<string>('')
  const [customerSearchResults, setCustomerSearchResults] = useState<Customer[]>([])
  const [activeTab, setActiveTab] = useState('Notes')
  const [isLoading, setIsLoading] = useState(false)
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false)
  const [isSubProductsModalOpen, setIsSubProductsModalOpen] = useState(false)
  const [currentSku, setCurrentSku] = useState('')
  const [companies, setCompanies] = useState<Company[]>([])
  const [users, setUsers] = useState<User[]>([])
  const [orderData, setOrderData] = useState<OrderData>({
    customer: null,
    paymentDetails: {
      method: 'none'
    },
    orderItems: [],
    orderTotal: 0,
    taxes: 0,
    totalProfit: 0,
    profitPercentage: 0,
    notes: '',
    orderStatus: 'pending',
    orderShippingStatus: 'pending_shipping',
    orderPaymentStatus: 'pending_payment',
    orderType: 'quote',
    orderSubType: 'showroom',
    orderSource: 'showroom_order',
    orderCompany: '',
    orderSalesRep: '', // Fix this to use current user ID
    orderDate: new Date(),
    createdDate: new Date(),
    promiseDate: new Date(),
  })


  // Fetch order data if ID passed
  useEffect(() => {
    if (!order) return
    console.log(`Fetch data for order ${order}`)

    const fetchData = async () => {
      try {
        const response = await fetch(`/api/v1/orders/${order}`)
        if (!response.ok) {
          console.log("Error fetching data")
        }

        const data = await response.json()
        setOrderData(data)
        calculateTotals()
      } catch {
        console.log("Error")
      }
    }

    fetchData()

  }, [order])

  const [productSuggestions, setProductSuggestions] = useState<Product[]>([]) // New state for product suggestions
  const suggestionsRef = useRef<HTMLDivElement | null>(null)

  const tabs = ['Notes', 'Timeline', 'Order Documents', 'Shipping Rates', 'Purchase Orders', 'Returns', 'P&L', 'Inventory']

  const handleSkuInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSkuInput(value)

    // Fetch product suggestions based on the input value
    if (value.length >= 3) {
      const response = await fetch(`/api/v1/products/search?query=${encodeURIComponent(value)}`)
      if (response.ok) {
        const data = await response.json()
        setProductSuggestions(data.results) // Assuming the API returns an array of products in 'results'
      } else {
        setProductSuggestions([]) // Clear suggestions on error
      }
    } else {
      setProductSuggestions([]) // Clear suggestions if input is less than 3 characters
    }
  }

  const addProductToOrder = (product: Product) => {
    // Logic to add the selected product to the order
    const newItem: OrderItem = {
      sku: product.sku,
      qty: 1,
      price: product.price,
      originalPrice: product.price,
      cost: product.cost,
      m20: product.m20,
      m25: product.m25,
      title: product.title, // {{ edit_1 }} Ensure title is included
      brand: product.brand, // {{ edit_2 }} Ensure brand is included
    }

    setOrderData(prevData => ({
      ...prevData,
      orderItems: [...prevData.orderItems, newItem],
    }))

    setSkuInput('') // Clear the SKU input
    setProductSuggestions([]) // Clear suggestions after selection
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault() // Prevent form submission if this input is part of a form
      if (autoAddOnScan) {
        addSkusToOrder()
      } else {
        if (!skuInput.endsWith(',')) {
          setSkuInput(prevInput => prevInput + ',')
        }
      }
    } else if (e.key === 'Escape') {
      setProductSuggestions([]) // Dismiss suggestions on escape
    }
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (suggestionsRef.current && !suggestionsRef.current.contains(event.target as Node)) {
      setProductSuggestions([]) // Dismiss suggestions
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleCustomerSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerSearch(e.target.value)
  }

  const handleOrderDetailChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target
    setOrderData(prev => ({ ...prev, [name]: value })) // Ensure the value is set correctly
  }

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    const selectedDate = value ? new Date(value) : null

    setOrderData({
      ...orderData,
      [name]: selectedDate,
    })
  }

  const postOrderData = async () => {
    const apiUrl = '/api/v1/orders/submit'

    // Ensure order information is set correctly
    const orderItems = orderData.orderItems || []
    const orderTotal = orderData.orderTotal || 0

    // Format dates to ISO string and include order data
    const formattedOrderData = {
      //...orderData,
      //orderDate: orderData.orderDate?.toISOString(),
      //createdDate: orderData.createdDate?.toISOString(),
      //promiseDate: orderData.promiseDate?.toISOString(),
      order: {
        id: order || null,
        ...orderData
      },
    }

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
        },
        body: JSON.stringify(formattedOrderData),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const result = await response.json()
      console.log('Order successfully posted:', result)

      if (result.success) {
        alert(`Order successfully ${order ? 'updated' : 'created'}!`)
        window.location.href = result.redirect || '/'
      } else {
        // If the API returns a success flag as false
        alert(result.error || `Failed to ${order ? 'update' : 'create'} order. Please try again.`)
      }
    } catch (error) {
      console.error('There was a problem posting the order:', error)
      alert(`An error occurred while ${order ? 'updating' : 'creating'} the order. Please try again.`)
    }
  }


  const handleOrderProcess = () => {
    postOrderData()
  }

  const searchCustomer = async () => {
    if (customerSearch.trim() === '') return

    try {
      const response = await fetch(`/api/v1/customers/search?query=${encodeURIComponent(customerSearch)}`)
      if (!response.ok) {
        throw new Error('Failed to fetch customers')
      }
      const data: Customer[] = await response.json()
      setCustomerSearchResults(data)
    } catch (error) {
      console.error('Error searching for customers:', error)
      // Optionally, display an error message to the user
    }
  }

  const selectCustomer = (customer: Customer) => {
    setOrderData(prevData => ({ ...prevData, customer }))
    setCustomerSearchResults([])
    setCustomerSearch('')
  }

  const addSkusToOrder = async () => {
    setIsLoading(true)
    // Remove trailing commas and filter out empty SKUs
    const skus = skuInput.replace(/,\s*$/, '').split(',').map(sku => sku.trim()).filter(Boolean)

    // Use a Map to keep track of SKUs and their quantities
    const skuMap = new Map<string, OrderItem>()

    for (const sku of skus) {
      try {
        // Check if we've already processed this SKU
        if (skuMap.has(sku)) {
          // If yes, just increment the quantity
          skuMap.get(sku)!.qty += 1
          continue
        }

        const response = await fetch(`/api/v1/products/sku/${sku}`)
        if (!response.ok) {
          throw new Error(`Failed to fetch product for SKU: ${sku}`)
        }
        const data = await response.json()
        if (data.success) {
          const { price, cost, m20, m25, title, brand } = data.data
          skuMap.set(sku, { title, brand, sku, qty: 1, price, originalPrice: price, cost, m20, m25 })
        } else {
          skuMap.set(sku, { sku, qty: 1, price: 0, originalPrice: 0, cost: 0 })
        }
      } catch (error) {
        console.error(`Error fetching product for SKU: ${sku}`, error)
        skuMap.set(sku, { sku, qty: 1, price: 0, originalPrice: 0, cost: 0 })
      }
    }

    // Convert the Map values to an array
    const newItems: OrderItem[] = Array.from(skuMap.values())

    setOrderData(prevData => {
      // Check for existing items and update quantities
      const updatedItems = prevData.orderItems.map(item => {
        const newItem = skuMap.get(item.sku)
        if (newItem) {
          // If the item already exists, add the new quantity
          skuMap.delete(item.sku) // Remove from skuMap to avoid duplicates
          return { ...item, qty: item.qty + newItem.qty }
        }
        return item
      })

      // Add any remaining new items
      return {
        ...prevData,
        orderItems: [...updatedItems, ...Array.from(skuMap.values())]
      }
    })

    setSkuInput('')
    setIsLoading(false)
  }

  const removeItem = (index: number) => {
    setOrderData(prevData => ({
      ...prevData,
      orderItems: prevData.orderItems.filter((_, i) => i !== index)
    }))
  }

  const updateOrderItem = (index: number, field: keyof OrderItem, value: number) => {
    setOrderData(prevData => {
      const updatedItems = [...prevData.orderItems]
      updatedItems[index] = {
        ...updatedItems[index],
        [field]: value,
        originalPrice: field === 'price' ? value : updatedItems[index].originalPrice
      }
      return { ...prevData, orderItems: updatedItems }
    })
  }

  const setFixedPercentagePrice = (index: number, amount: number) => {
    setOrderData(prevData => {
      const updatedItems = [...prevData.orderItems]
      const item = updatedItems[index]
      //const newPrice = item.originalPrice * (percentage / 100)
      const newPrice = amount
      updatedItems[index] = { ...item, price: newPrice }
      return { ...prevData, orderItems: updatedItems }
    })
  }

  const resetPrice = (sku: string) => {
    setOrderData(prevData => ({
      ...prevData,
      orderItems: prevData.orderItems.map(item =>
        item.sku === sku ? { ...item, price: item.originalPrice } : item
      )
    }))
  }

  const calculateTotals = () => {
    const total = orderData.orderItems.reduce((sum, item) => sum + item.price * item.qty, 0)
    const totalCost = orderData.orderItems.reduce((sum, item) => sum + item.cost * item.qty, 0)
    const profit = total - totalCost

    const percentage = total > 0 ? ((profit / total) * 100) : 0
    const roundedPercentage = Number(percentage.toFixed(2))

    setOrderData(prevData => ({
      ...prevData,
      orderTotal: total,
      totalProfit: profit,
      profitPercentage: roundedPercentage,
      taxes: total * 0.1 // Example tax calculation: 10%
    }))
  }

  useEffect(() => {
    calculateTotals()
  }, [orderData.orderItems])

  const fetchOptions = async () => {
    try {
      const response = await fetch("/api/v1/orders/options")
      if (!response.ok) {
        console.log("Error fetching options")
      }

      const data = await response.json()
      setCompanies(data.companies)
      setUsers(data.users)

    } catch {
      console.log("Error")
    }
  }

  useEffect(() => {
    fetchOptions()
  }, [])

  const openSubProductsModal = (sku: string) => {
    setCurrentSku(sku)
    setIsSubProductsModalOpen(true)
  }

  const addSubProducts = (subProducts: OrderItem[]) => {
    setOrderData(prevData => ({
      ...prevData,
      orderItems: [
        ...prevData.orderItems,
        ...subProducts.map(item => ({ ...item, parentSku: currentSku }))
      ]
    }))
    setIsSubProductsModalOpen(false)
  }

  const formatNumber = (num: number | null | undefined): string => {
    if (num === null || num === undefined) {
      return '0.00' // or return an empty string '', depending on your preference
    }

    return num.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }


  const renderOrderItems = () => {
    if (orderData.orderItems.length === 0) {
      return (
        <div className="text-center py-8 text-space-400 bg-space-900 rounded-md p-4">
          No items added to the order yet. Use the input above to add SKUs.
        </div>
      )
    }

    return orderData.orderItems.map((item, index) => (
      <ItemCard
        key={item.sku}
        item={item}
        onRemove={() => removeItem(index)}
        onQuantityChange={(newQuantity: number) => updateOrderItem(index, 'qty', newQuantity)}
        onPriceChange={(newPrice: number) => updateOrderItem(index, 'price', newPrice)}
        onCostChange={(newCost: number) => updateOrderItem(index, 'cost', newCost)}
        //setFixedPercentagePrice={(percentage: number) => setFixedPercentagePrice(index, percentage)}
        onAddonsClick={() => openSubProductsModal(item.sku)} // Pass the function to open the modal
        resetPrice={() => resetPrice(item.sku)}
      />
    ))
  }

  return (
    <div className="flex gap-4 pb-4 bg-space-950 text-space-100">
      <aside className="w-1/5 flex flex-col gap-4">
        <CollapsibleSection title="Order Details">
          <div className="space-y-4">
            {/* Company Selector  */}
            <div className="flex flex-col space-y-2">
              <div className="flex flex-col gap-2">
                <label className="w-32 flex-shrink-0 text-space-500 font-medium text-xs uppercase tracking-wide">Company</label>
                <select
                  name="orderCompany"
                  value={orderData.orderCompany}
                  onChange={(e) => setOrderData(prev => ({ ...prev, orderCompany: e.target.value.replace(/ /g, '_') }))}
                  className="flex-grow p-2 bg-space-900 text-space-100 border-space-900 rounded focus:border-blue-500 focus:outline-none"
                >
                  {
                    companies.map((company) => {
                      return <option key={company.id} value={company.sub_domain}>{company.name}</option>
                    })
                  }
                </select>
              </div>
            </div>
            {/* Order Status */}
            <div className="flex flex-col space-y-2">
              <div className="flex flex-col gap-2">
                <label className="w-32 flex-shrink-0 text-space-500 font-medium text-xs uppercase tracking-wide">Status Queue</label>
                <select
                  name="orderStatus"
                  value={orderData.orderStatus}
                  onChange={handleOrderDetailChange}
                  className="flex-grow p-2 bg-space-900 text-space-100 border-space-900 rounded focus:border-blue-500 focus:outline-none"
                >
                  <option value="pending">Pending</option>
                  <option value="processing">Processing</option>
                  <option value="in_transit">In Transit</option>
                  <option value="completed">Completed</option>
                  <option value="order_problem">Order Problem</option>
                  <option value="canceled">Canceled</option>
                </select>
              </div>
            </div>
            {/* Shipping Status */}
            <div className="flex flex-col space-y-2">
              <div className="flex flex-col gap-2">
                <label className="w-32 flex-shrink-0 text-space-500 font-medium text-xs uppercase tracking-wide">Shipping Status</label>
                <select
                  name="orderShippingStatus"
                  value={orderData.orderShippingStatus}
                  onChange={handleOrderDetailChange}
                  className="flex-grow p-2 bg-space-900 text-space-100 border-space-900 rounded focus:border-blue-500 focus:outline-none"
                >
                  pending: 0, label_created: 1, pending_pickup: 2, shipped: 3, delivered: 100, returned_to_warehouse: 200, delivery_issue: 300
                  <option value="pending_shipping">Pending Shipping</option>
                  <option value="label_created">Label Created</option>
                  <option value="pending_pickup">Pending Pickup</option>
                  <option value="shipped">Shipped</option>
                  <option value="delivered">Delivered</option>
                  <option value="returned_to_warehouse">Returned to Warehouse</option>
                  <option value="delivery_issue">Delivery Issue</option>
                </select>
              </div>
            </div>
            {/* Payment Status */}
            <div className="flex flex-col space-y-2">
              <div className="flex flex-col gap-2">
                <label className="w-32 flex-shrink-0 text-space-500 font-medium text-xs uppercase tracking-wide">Payment Status</label>
                <select
                  name="orderPaymentStatus"
                  value={orderData.orderPaymentStatus}
                  onChange={handleOrderDetailChange}
                  className="flex-grow p-2 bg-space-900 text-space-100 border-space-900 rounded focus:border-blue-500 focus:outline-none"
                >
                  <option value="pending_payment">Pending Payment</option>
                  <option value="paid">Paid</option>
                </select>
              </div>
            </div>
            {/* Type selector */}
            <div className="flex flex-col space-y-2">
              <div className="flex flex-col gap-2">
                <label className="w-32 flex-shrink-0 text-space-500 font-medium text-xs uppercase tracking-wide">Type</label>
                <select
                  name="orderType"
                  value={orderData.orderType}
                  onChange={handleOrderDetailChange}
                  className="flex-grow p-2 bg-space-900 text-space-100 border-space-900 rounded focus:border-blue-500 focus:outline-none"
                >
                  <option value="draft">Draft</option>
                  <option value="quote">Quote</option>
                  <option value="retail_order">Retail Order</option>
                  <option value="b2b_order">B2B Order</option>
                  <option value="builder_order">Builder Order</option>
                  <option value="marketplace_order">Marketplace Order</option>
                </select>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <div className="flex flex-col gap-2">
                <label className="w-32 flex-shrink-0 text-space-500 font-medium text-xs uppercase tracking-wide">Sub-Type</label>
                <select
                  name="orderSubType"
                  value={orderData.orderSubType}
                  onChange={handleOrderDetailChange}
                  className="flex-grow p-2 bg-space-900 text-space-100 border-space-900 rounded focus:border-blue-500 focus:outline-none"
                >
                  <option value="st_not_set">Not Set</option>
                  <option value="showroom">Showroom</option>
                  <option value="amazon">Amazon</option>
                  <option value="walmart">Walmart</option>
                  <option value="sears">Sears</option>
                  <option value="wholesale">Wholesale</option>
                </select>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <div className="flex flex-col gap-2">
                <label className="w-32 flex-shrink-0 text-space-500 font-medium text-xs uppercase tracking-wide">Source</label>
                <select
                  name="orderSource"
                  value={orderData.orderSource}
                  onChange={handleOrderDetailChange}
                  className="flex-grow p-2 bg-space-900 text-space-100 border-space-900 rounded focus:border-blue-500 focus:outline-none"
                >
                  <option value="os_not_set">Not Set</option>
                  <option value="online_order">Online Order</option>
                  <option value="phone_order">Phone Order</option>
                  <option value="showroom_order">Showroom Order</option>
                </select>
              </div>
            </div>
            {/* Sales Rep Selector  */}
            <div className="flex flex-col space-y-2">
              <div className="flex flex-col gap-2">
                <label className="w-32 flex-shrink-0 text-space-500 font-medium text-xs uppercase tracking-wide">Rep</label>
                <select
                  name="orderSalesRep"
                  value={orderData.orderSalesRep}
                  onChange={(e) => setOrderData(prev => ({ ...prev, orderSalesRep: e.target.value }))}
                  className="flex-grow p-2 bg-space-900 text-space-100 border-space-900 rounded focus:border-blue-500 focus:outline-none"
                >
                  {
                    users.map((user) => {
                      return <option key={user.id} value={user.id}>{user.username}</option>
                    })
                  }
                </select>
              </div>
            </div>
            <div className="bg-space-900 p-4 rounded-md space-y-4">
              {/* Order Date Selector  */}
              <div className="flex flex-col space-y-2">
                <div className="flex flex-col gap-2">
                  <label className="w-32 flex-shrink-0 text-space-500 font-medium text-xs uppercase tracking-wide">Order Date</label>
                  <input
                    type="date"
                    id="orderDate"
                    name="orderDate"
                    className="flex-grow p-2 bg-space-950 text-space-100 border-space-950 rounded focus:border-blue-500 focus:outline-none"
                    value={orderData.orderDate ? new Date(orderData.orderDate).toISOString().split('T')[0] : ''}
                    onChange={handleDateChange}
                    disabled
                  />
                </div>
              </div>
              {/* Created Date Selector  */}
              <div className="flex flex-col space-y-2">
                <div className="flex flex-col gap-2">
                  <label className="w-32 flex-shrink-0 text-space-500 font-medium text-xs uppercase tracking-wide">Created Date</label>
                  <input
                    type="date"
                    id="createdDate"
                    name="createdDate"
                    className="flex-grow p-2 bg-space-950 text-space-100 border-space-950 rounded focus:border-blue-500 focus:outline-none"
                    value={orderData.createdDate ? new Date(orderData.createdDate).toISOString().split('T')[0] : ''}
                    onChange={handleDateChange}
                    disabled
                  />
                </div>
              </div>
              {/* Promise Date Selector  */}
              <div className="flex flex-col space-y-2">
                <div className="flex flex-col gap-2">
                  <label className="w-32 flex-shrink-0 text-space-500 font-medium text-xs uppercase tracking-wide">Promise Date</label>
                  <input
                    type="date"
                    id="promiseDate"
                    name="promiseDate"
                    className="flex-grow p-2 bg-space-950 text-space-100 border-space-900 rounded focus:border-blue-500 focus:outline-none"
                    value={orderData.promiseDate ? new Date(orderData.promiseDate).toISOString().split('T')[0] : ''}
                    onChange={handleDateChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </CollapsibleSection>
      </aside>
      <main className="flex-1 flex flex-col gap-4">
        <section className="bg-space-800 p-4 rounded">
          <div className="flex items-center gap-3 mb-4 relative">
            <input
              type="text"
              value={skuInput}
              onChange={handleSkuInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Enter SKU(s) or product name"
              className="bg-space-900 text-space-100 border border-space-900 p-2 rounded flex-1 placeholder-space-600"
            />
            {/* Suggestions dropdown */}
            {productSuggestions.length > 0 && (
              <div
                ref={suggestionsRef}
                className="absolute top-10 bg-space-800 border border-space-900 rounded mt-2 w-full z-10 shadow-xl max-h-60 overflow-y-auto"
              >
                {productSuggestions.map(product => (
                  <div
                    key={product.id}
                    onClick={() => addProductToOrder(product)}
                    className="cursor-pointer hover:bg-space-700 p-2 flex items-center space-x-2"
                  >
                    {/* Placeholder for product image */}
                    <div className="w-12 h-12 bg-black rounded flex items-center justify-center">
                      <span className="text-space-300">Image</span> {/* Placeholder text */}
                    </div>
                    <div className="flex-1">
                      <p className="font-semibold">{product.title} - {product.sku}</p>
                      <p className="text-sm text-space-300">${product.price.toFixed(2)}</p> {/* Display price */}
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className='rounded px-4 py-2 bg-space-700 flex items-center gap-2'>
              <input
                type="checkbox"
                value={String(autoAddOnScan)}
                onChange={() => setAutoAddOnScan(!autoAddOnScan)}
                checked={autoAddOnScan}
                className='rounded'
              />
              <label>Auto add on scan</label>
            </div>
            <button
              onClick={addSkusToOrder}
              disabled={isLoading}
              className="bg-green-500 text-white rounded px-4 py-2.5 font-bold text-sm hover:bg-green-700 h-full disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? (
                <span className="flex items-center">
                  <Loader className="animate-spin mr-2" size={16} />
                  Adding...
                </span>
              ) : (
                'Add SKU(s)'
              )}
            </button>
          </div>
          {isLoading && (
            <div className="mt-2 text-space-300">
              Loading SKU data...
            </div>
          )}
          <div className="space-y-2">
            {renderOrderItems()}
          </div>
        </section>
        <section>
          <div className="flex gap-2">
            {tabs.map((tab) => (
              <button
                key={tab}
                className={`px-4 py-2 font-semibold rounded-t-lg border-b border-space-900 text-sm ${activeTab === tab
                  ? 'bg-space-700 text-space-100'
                  : 'bg-space-800 text-space-300 hover:bg-space-700'
                  }`}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </button>
            ))}
          </div>
          <div className="p-4 bg-space-800 rounded-b-lg rounded-tr-lg">
            <TabContent tab={activeTab} />
          </div>
        </section>
      </main>
      <aside className="w-1/5 flex flex-col gap-4">
        <CollapsibleSection title="Quick Actions">
          <button
            className={`bg-green-500 text-white rounded w-full px-3 py-2 hover:bg-green-600 ${orderData.orderItems.length <= 0 && 'opacity-50'}`}
            onClick={handleOrderProcess}
            disabled={orderData.orderItems.length <= 0}
          >{order ? "Update" : "Create"} Order</button>
        </CollapsibleSection>
        <CollapsibleSection title="Customer Details">
          {orderData.customer ? (
            <div className='w-fit'>
              <div className="flex justify-between items-start mb-2">
                <div className='w-full'>
                  <h3 className="font-semibold text-lg">{orderData.customer.name}</h3>
                  <p className='text-wrap text-sm'>{orderData.customer.email}</p>
                </div>
                <div className='flex-shrink-0'>
                  <button
                    onClick={() => setIsCustomerModalOpen(true)}
                    className="text-blue-400 hover:text-blue-300 mr-2"
                  >
                    <Edit size={16} />
                  </button>
                  <button
                    onClick={() => {
                      setOrderData(prevData => ({ ...prevData, customer: null }))
                      setCustomerSearch('')
                    }}
                    className="text-red-400 hover:text-red-300"
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
              </div>
              {orderData.customer.addresses.billing && (
                <div className='bg-space-900 p-2 rounded mb-2'>
                  <h4 className="font-semibold">Billing Address</h4>
                  <p>{orderData.customer.addresses.billing.address_1}</p>
                  <p>{orderData.customer.addresses.billing.address_2}</p>
                  <p>{`${orderData.customer.addresses.billing.city}, ${orderData.customer.addresses.billing.state} ${orderData.customer.addresses.billing.zip_code}`}</p>
                  <p>{orderData.customer.addresses.billing.country}</p>
                  <p>Phone: {orderData.customer.addresses.billing.phone}</p>
                </div>
              )}
              {orderData.customer.addresses.shipping && (
                <div className='bg-space-900 p-2 rounded'>
                  <h4 className="font-semibold">Shipping Address</h4>
                  <p>{orderData.customer.addresses.shipping.address_1}</p>
                  <p>{orderData.customer.addresses.shipping.address_2}</p>
                  <p>{`${orderData.customer.addresses.shipping.city}, ${orderData.customer.addresses.shipping.state} ${orderData.customer.addresses.shipping.zip_code}`}</p>
                  <p>{orderData.customer.addresses.shipping.country}</p>
                  <p>Phone: {orderData.customer.addresses.shipping.phone}</p>
                </div>
              )}
            </div>
          ) : (
            <div>
              <div className="flex mb-2">
                <input
                  type="text"
                  value={customerSearch}
                  onChange={(e) => setCustomerSearch(e.target.value)}
                  placeholder="Search customer..."
                  className="bg-space-900 text-space-100 border-space-900 p-2 rounded-l w-full placeholder-space-400"
                />
                <button
                  onClick={searchCustomer}
                  className="bg-green-500 text-white rounded-r px-3 py-2 hover:bg-green-600"
                >
                  <Search size={20} />
                </button>
              </div>
              <button
                onClick={() => setIsCustomerModalOpen(true)}
                className="text-blue-400"
              >
                Manual Entry
              </button>
              {customerSearchResults.length > 0 && (
                <div className="mt-2 max-h-60 overflow-y-auto p-2 border border-dashed border-space-700 rounded space-y-1">
                  {customerSearchResults.map((customer) => (
                    <div
                      key={customer.id}
                      onClick={() => selectCustomer(customer)}
                      className="cursor-pointer hover:bg-space-950 p-2 rounded bg-space-900"
                    >
                      <p className="font-semibold">{customer.name}</p>
                      <p className="text-sm text-space-300">{customer.email}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </CollapsibleSection>
        <CollapsibleSection title="Payment Details">
          <PaymentDetailsInput
            paymentDetails={orderData.paymentDetails}
            setPaymentDetails={(newDetails: any) => setOrderData(prev => ({ ...prev, paymentDetails: newDetails }))}
          />
        </CollapsibleSection>
        <CollapsibleSection title="Order Summary">
          <div className="space-y-2">
            <p className="text-lg font-semibold">Total:&nbsp;
              <span className="">${formatNumber(orderData.orderTotal)}</span>
            </p>
            <p className="text-lg font-semibold">Taxes:&nbsp;
              <span className="">${formatNumber(orderData.taxes)}</span>
            </p>
            <div className="bg-space-900 p-4 rounded">
              <p className="text-lg font-semibold">Profit:&nbsp;
                <span className="">${formatNumber(orderData.totalProfit)}</span>
              </p>
              <p className="text-sm text-gray-600">Profit Percentage:&nbsp;
                <span className="font-semibold">{orderData.profitPercentage.toFixed(2)}%</span>
              </p>
            </div>
          </div>
        </CollapsibleSection>

      </aside>
      {
        isCustomerModalOpen && (
          <CustomerEditModal
            customer={orderData.customer}
            onClose={() => setIsCustomerModalOpen(false)}
            onSave={(updatedCustomer: any) => {
              setOrderData(prev => ({ ...prev, customer: updatedCustomer }))
              setIsCustomerModalOpen(false)
            }}
          />
        )
      }
      {
        isSubProductsModalOpen && (
          <SubProductsModal
            sku={currentSku}
            onClose={() => setIsSubProductsModalOpen(false)}
            onAdd={addSubProducts}
          />
        )
      }
    </div >
  )
}

export default NewOrder